const homeRouter = [
    {
        //内容中心
        path: 'substance',
        name: 'substance',
        component: () => import(/* webpackChunkName: "home/substance/index" */ '../views/home/substance/index/index.vue'),
        meta: {
            authkey: ["4-1","4-2","4-3","4-4","4-5","4-6"]
        }
    },
    {
        //内容中心--下发列表
        path: 'substance/send',
        name: 'substanceSend',
        component: () => import(/* webpackChunkName: "home/substance/send" */ '../views/home/substance/send/index.vue'),
    },
    {
        //内容中心--内容审核
        path: 'substance/verify',
        name: 'substanceVerify',
        component: () => import(/* webpackChunkName: "home/substance/verify" */ '../views/home/substance/verify/index.vue'),
    },
    {
        //专题制作
        path: 'subject',
        name: 'subject',
        component: () => import(/* webpackChunkName: "home/subject" */ '../views/home/subject/index.vue'),
        props: (route) => ({ id: route.query.id }),
        meta: {
            authkey: ["6-1"]
        }
    },
    //--------------------------------------------
    {
        //任务中心
        path: 'task',
        name: 'task',
        component: () => import(/* webpackChunkName: "home/task" */ '../views/home/task/index.vue'),
        meta: {
            authkey: ["8-1","8-2","8-3","8-4"]
        }
    },
    {
        //任务详情
        path: 'task/detail',
        name: 'taskDetail',
        component: () => import(/* webpackChunkName: "home/task" */ '../views/home/task/detail/index.vue'),
    },
    {
        //经销商任务详情
        path: 'task/dealer/detail',
        name: 'taskDealerDetail',
        component: () => import(/* webpackChunkName: "home/task" */ '../views/home/task/dealer/detail/index.vue'),
    },
    {
        //经销商端  任务中心 详情
        path: 'task/dealer/center/detail',
        name: 'taskDealerCenterDetail',
        component: () => import(/* webpackChunkName: "home/task" */ '../views/home/task/dealer/center/detail/index.vue'),
        meta: {
            authkey: ["8-1"]
        }
    },
    //--------------------------------------------
    //抖快视界 vision/field
    {
        path: 'vision/field',
        name: 'visionField',
        component: () => import(/* webpackChunkName: "home/vision/field" */ '../views/home/vision/field/index.vue'),
        meta: {
            authkey: ["12-1","12-2","12-3","12-4"]
        }
    },
    //热点植入 hot/index/index
    {
        path: 'hot/index/index',
        name: 'hotPointIndex',
        component: () => import(/* webpackChunkName: "home/hot/index/index" */ '../views/home/hot/index/index.vue'),
    },
    //AI创作   ai/product
    {
        path: 'ai/product',
        name: 'aiProduct',
        component: () => import(/* webpackChunkName: "home/ai/product" */ '../views/home/ai/product/index.vue'),
    },
    //线索管理 clew
    {
        path: 'clew',
        name: 'clew',
        component: () => import(/* webpackChunkName: "home/clew" */ '../views/home/clew/index.vue'),
        meta: {
            authkey: ["7-1"]
        }
    },
    //素材管理 material
    {
        path: 'material',
        name: 'material',
        component: () => import(/* webpackChunkName: "home/material/index" */ '../views/home/material/index.vue'),
        meta: {
            authkey: ["5-1","5-2","5-3","5-4","5-5","5-6"]
        }
    },
    {
        //素材管理--下发列表
        path: 'material/send',
        name: 'materialSend',
        component: () => import(/* webpackChunkName: "home/material/send" */ '../views/home/material/send/index.vue'),
    },
    {
        //素材管理--素材审核
        path: 'material/verify',
        name: 'materialVerify',
        component: () => import(/* webpackChunkName: "home/material/verify" */ '../views/home/material/verify/index.vue'),
    },
    //------------------------------------------
    //分析报表 report
    {
        path: 'report',
        name: 'report',
        component: () => import(/* webpackChunkName: "home/report" */ '../views/home/report/index.vue'),
        meta: {
            authkey: ["9-1","9-2","9-3"]
        }
    },
    {
        //素材分析报表->经销商详情
        path: 'report/material/dealer/detail',
        component: () => import(/* webpackChunkName: "home/report" */ '../views/home/report/material/dealer/detail/index.vue'),
    },
    {
        //经销商报表->内容详情
        path: 'report/dealer/material/detail',
        component: () => import(/* webpackChunkName: "home/report" */ '../views/home/report/dealer/material/detail/index.vue'),
    },
    {
        //经销商报表->顾问详情
        path: 'report/dealer/adviser/detail',
        component: () => import(/* webpackChunkName: "home/report" */ '../views/home/report/dealer/adviser/detail/index.vue'),
    },
    {
        //顾问报表->素材详情
        path: 'report/adviser/material/detail',
        component: () => import(/* webpackChunkName: "home/report" */ '../views/home/report/adviser/material/detail/index.vue'),
    },
    //-----------------------------------------
    //在线客服 chat
    {
        path: 'chat',
        name: 'chat',
        component: () => import(/* webpackChunkName: "home/chat" */ '../views/home/chat/index.vue'),
    },
    //在线客服主机 chat
    {
        path: 'chat/dealer',
        name: 'chatdealer',
        component: () => import(/* webpackChunkName: "home/chat" */ '../views/home/chat/dealer/index.vue'),
    },
    //用户管理 user
    {
        path: 'user',
        name: 'user',
        component: () => import(/* webpackChunkName: "home/user" */ '../views/home/user/index.vue'),
        meta: {
            authkey: ["3-1","3-2","3-3","3-4","3-5"]
        }
    },
    //角色管理 role
    {
        path: 'role',
        name: 'role',
        component: () => import(/* webpackChunkName: "home/role" */ '../views/home/role/index.vue'),
        meta: {
            authkey: ["2-1","2-2","2-3","2-4"]
        }
    },
    //经销商管理 dealer
    {
        path: 'dealer',
        name: 'dealer',
        component: () => import(/* webpackChunkName: "home/dealer" */ '../views/home/dealer/index.vue'),
    },
    //组织架构 organization
    {
        path: 'organization',
        name: 'organization',
        component: () => import(/* webpackChunkName: "home/organization" */ '../views/home/organization/index.vue'),
    },
    //个人中心 self/center
    {
        path: 'self/center',
        name: 'selfCenter',
        component: () => import(/* webpackChunkName: "home/self/center" */ '../views/home/self/center/index.vue'),
    },
    //收藏列表 favorite/list
    {
        path: 'favorite/list',
        name: 'favoriteList',
        component: () => import(/* webpackChunkName: "home/favorite/list" */ '../views/home/favorite/list/index.vue'),
    },
    //帮助中心 help/center
    {
        path: 'help/center',
        name: 'helpCenter',
        component: () => import(/* webpackChunkName: "home/help/center" */ '../views/home/help/center/index.vue'),
    },
    //系统配置
    {
        path: 'system/config',
        name: 'systemConfig',
        component: () => import(/* webpackChunkName: "home/system/config" */ '../views/home/system/config/index.vue'),
        meta: {
            authkey: ["11-1"]
        }
    },
    {
      path: 'log/admin',
      name: 'logAdmin',
      component: () => import(/* webpackChunkName: "home/log/admin" */ '../views/home/log/admin/index.vue'),
    },
    // 角色用户 roleAndUser
    {
        path: 'roleAndUser',
        name: 'roleAndUser',
        component: () => import(/* webpackChunkName: "home/roleAndUser" */ '../views/home/roleAndUser/index.vue'),
        meta: {
            authkey: ["2-1","2-2","2-3","2-4","3-1","3-2","3-3","3-4","3-5"]
        }
    },
    // 门店管理 store
    {
        path: 'store',
        name: 'store',
        component: () => import(/* webpackChunkName: "home/store" */ '../views/home/store/index.vue'),
        meta: {
            authkey: ["1-1","1-2","1-3","1-4","10-2","10-3","10-4"]
        }
    },
]

homeRouter.push({
    //没有匹配到的路由都进入404页面
    path: 'noPermission',
    name: 'noPermission',
    component: () => import(/* webpackChunkName: "home/errorPage/noPermission" */ '../views/home/errorPage/noPermission.vue'),
});

homeRouter.push({
    //没有匹配到的路由都进入404页面
    path: '*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "home/errorPage/404" */ '../views/home/errorPage/404.vue'),
});

module.exports = homeRouter;
